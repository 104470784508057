<template>
    <section class="bg-white dark:bg-gray-900">
        <div
            class="container min-h-screen px-6 py-12 mx-auto lg:flex lg:items-center lg:gap-12"
        >
            <div class="wf-ull lg:w-1/2">
                <p class="text-9xl font-bold my-0">Ой!</p>
                <h1
                    class="mt-3 mb-0 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl"
                >
                    Похоже мы не можем найти нужную вам страницу
                </h1>
                <h2 class="my-0">Все, что мы нашли это томат</h2>
                <h3 class="my-0 text-gray-500 dark:text-gray-400">
                    Код ошибки: {{ error.statusCode }}
                </h3>
                {{ error.message }}
                <p class="mt-4 mb-0 text-gray-500 dark:text-gray-400">
                    Вот несколько полезных ссылок:
                </p>
                <ul class="my-0">
                    <li>
                        <a
                            class="mt-4 text-gray-500 dark:text-gray-400 underline decoration-dashed"
                            href="/profiles"
                            >Сообщество Умный огород</a
                        >
                    </li>
                    <li>
                        <a
                            class="mt-4 text-gray-500 dark:text-gray-400 underline decoration-dashed"
                            href="/catalog"
                            >Каталог семян</a
                        >
                    </li>
                    <li>
                        <a
                            class="mt-4 text-gray-500 dark:text-gray-400 underline decoration-dashed"
                            href="/manuals"
                            >Руководства</a
                        >
                    </li>
                </ul>
                <div class="flex items-center mt-6 gap-x-3">
                    <a
                        href="/"
                        class="w-1/2 px-5 py-2 text-m tracking-wide text-white transition-colors duration-200 bg-green-600 rounded-lg shrink-0 sm:w-auto hover:bg-green-500 dark:hover:bg-blue-500 dark:bg-green-600"
                    >
                        Перейти на главную
                    </a>
                </div>
            </div>

            <div class="relative w-full mt-8 lg:w-1/2 lg:mt-0">
                <img
                    class="w-full lg:h-[32rem] h-80 md:h-96 rounded-lg object-cover"
                    src="~/assets/misc/404-1.webp"
                    alt="Умный огород 404"
                />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["error"],
    layout: "error", // you can set a custom layout for the error page
};
</script>
